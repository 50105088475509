<template>
<div>
    <v-container class="station_box relative-center" v-if="show">
        <h1 class="mb-3">ガソリンスタンドの登録</h1>
        <p>
            施工を行うガソリンスタンド情報を登録できます
            <br>
            設定を変更後「保存する」をクリックしてください
        </p>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
        <v-form ref="form">
            <v-card class="pa-6 mb-6" v-for="(na,i) in names.length" :key="i">
                <div class="f-14 bold f-brown float-left mb-3">
                    【{{i+1}}】
                </div>
                <v-icon v-show=" i>0" class="float-right relative t-2 r-4 f-gray" @click="deleted(i)">fas fa-times</v-icon>
                <div class="clear" />
                <v-text-field v-model="names[i]" :rules="requredRules" label="ガソリンスタンド名*" placeholder="ENEOS東品川店" outlined type="text" maxlength="255" />
                <v-text-field v-model="zips[i]" :rules="zipRulesRequired" label="郵便番号*" outlined type="text" maxlength="8" class="zip" />
                <div class="bold text-left mb-1">－&nbsp;住&nbsp;所&nbsp;&nbsp;－</div>
                <div class="text-left pb-6 pl-2">
                    {{addresses[i]}}<span v-if="!addresses[i]">郵便番号を入力してください</span></div>
                <v-text-field v-model="addresses2[i]" label="丁番地*" :rules="requredRules" type="text" outlined maxlength="255" />
                <v-text-field v-model="tels[i]" label="電話番号*" :rules="requredRules" type="text" outlined maxlength="15" class="zip" />
            </v-card>
        </v-form>
        <div class="text-left">
            <v-btn color="#5D4037" dark large class="f-11" @click="plus">
                <v-icon class="f-10 pr-1">fas fa-plus</v-icon>追加
            </v-btn>
        </div>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <Snackbar ref="snack" />
</div>
</template>

<script>
import Form from '@/mixins/form'
import {
    Core as YubinBangoCore
} from 'yubinbango-core';
import Snackbar from "@/components/snackbar.vue"

import axios from "axios"

export default {
    mixins: [Form],
    data() {
        return {
            zipRulesRequired: [
                v => (!v || /^\d{3}-?\d{4}$/.test(v) || /^\d{3}?\d{4}$/.test(v)) || '郵便番号の形式が異なります(半角数字、ハイフン)',
                (v) => !!v || "入力必須です"
            ],
            names: [null],
            zips: [null],
            addresses: [null],
            addresses2: [null],
            tels: [null],
            show: false
        }
    },
    components: {
        Snackbar
    },
    computed: {
        Station() {
            return this.$store.getters["user/station"];
        },
    },
    created() {
        for (let i = 0; i < this.Station.length; i++) {
            this.names[i] = this.Station[i].name
            this.zips[i] = this.Station[i].zip
            this.addresses[i] = this.Station[i].address
            this.addresses2[i] = this.Station[i].address2
            this.tels[i] = this.Station[i].tel
        }
        this.show = true
    },
    methods: {
        plus() {
            this.names.push(null)
            this.zips.push(null)
            this.addresses.push(null)
            this.addresses2.push(null)
            this.tels.push(null)
        },
        deleted(i) {
            if (!confirm("削除しますか")) {
                return false
            }
            this.names.splice(i, 1)
            this.zips.splice(i, 1)
            this.addresses.splice(i, 1)
            this.addresses2.splice(i, null)
            this.tels.splice(i, 1)
        },
        async submit() {
            if (!this.$refs.form.validate()) {
                return false;
            }
            const data = {
                names: JSON.stringify(this.names),
                zips: JSON.stringify(this.zips),
                addresses: JSON.stringify(this.addresses),
                addresses2: JSON.stringify(this.addresses2),
                tels: JSON.stringify(this.tels),
            }

            const response = await axios.post("/user/station/save", data)
            if (response.data.station) {
                await this.$store.dispatch("user/stationSave", response.data.station);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            } else {
                alert("エラーが発生しました")
            }
        }
    },
    watch: {
        zips: {
            async handler() {
                for (let i = 0; i < this.zips.length; i++) {
                    try {
                        if (this.zips[i].match(/^\d{3}-?\d{4}$/) || this.zips[i].match(/^\d{3}?\d{4}$/)) {
                            new YubinBangoCore(this.zips[i], (addr) => {
                                let address = ""
                                address = addr.region // 都道府県
                                address += addr.locality // 市区町村
                                address += addr.street // 町域
                                this.$set(this.addresses, i, address);
                            })
                        }
                    } catch (error) {
                        console.log()
                    }
                }
            },
            deep: true
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/station.scss";
</style>
